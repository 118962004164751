import React from "react";

export default function Loader() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "absolute",
        zIndex: 9999,
      }}
    >
      <img
        style={{
          height: "100px",
        }}
        class="flip-vertical-right"
        src="logo.png"
      />
    </div>
  );
}
