import moment from "moment";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { accountLogAPI, getCollectionReportAPI } from "../service/AuthService";
import { useQuery } from "react-query";
import ReactSelect from "react-select";

export default function GeneralLedger() {
  const { userType } = useParams();
  const isCollectionReport = true;
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const [isLenaDena, setIsLenaDena] = React.useState(
    location.state?.isLenaDena
  );
  const [payload, setPayload] = React.useState({
    endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    startDate: moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD HH:mm:ss"),
    pageSize: 100,
    search: "",

    userName: location.state?.client?.username,
  });

  const navigate = useNavigate();
  const [
    collectionReportMapping,
    setCollectionReportMapping,
  ] = React.useState({
    lenaHai: [],
    denaHai: [],
  });
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [allClients, setAllClients] = React.useState([]);

  const getCollectionReport = async () => {
    const { response, code } = await getCollectionReportAPI(
      payload.startDate,
      payload.endDate,
      location.state?.isLenaDena ? "" : params?.userType,
      location.state?.isLenaDena ? params?.userType : ""
    );
    if (code === 200) {
      let data = response?.collectionReport;
      let lenaHai;
      let denaHai;
      if (selectedUser) {
        lenaHai = data?.filter(
          (item) =>
            item?.balance > 0 && item?.userId === selectedUser?.value
        );
        denaHai = data?.filter(
          (item) =>
            item?.balance < 0 && item?.userId === selectedUser?.value
        );
      } else {
        lenaHai = data?.filter((item) => item?.balance > 0);
        denaHai = data?.filter((item) => item?.balance < 0);
      }

      setCollectionReportMapping({
        lenaHai,
        denaHai,
      });
      const allClients = data.map((item) => ({
        value: item?.userId,
        label: item?.userName,
      }));
      setAllClients(allClients);
    }
  };
  useEffect(() => {
    if (isCollectionReport) {
      getCollectionReport();
    }
  }, [params?.userType]);

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-agentledger _nghost-ng-c3383274413="" className="ng-star-inserted">
        <div _ngcontent-ng-c3383274413="" className="page_header">
          <h1 _ngcontent-ng-c3383274413="" className="page_heading">
            {userType} Ledger{" "}
          </h1>
          <div _ngcontent-ng-c3383274413="" className="breadcrumb_block">
            <nav _ngcontent-ng-c3383274413="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c3383274413="" className="breadcrumb">
                <li _ngcontent-ng-c3383274413="" className="breadcrumb-item">
                  <a _ngcontent-ng-c3383274413="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li _ngcontent-ng-c3383274413="" className="breadcrumb-item">
                  <a
                    _ngcontent-ng-c3383274413=""
                    href="#/ledger/ledger-dashboard"
                  >
                    ledger
                  </a>
                </li>
                <li
                  _ngcontent-ng-c3383274413=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {userType} Ledger
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c3383274413=""
          className="Super_master_details_section agentledger_block"
        >
          <div
            _ngcontent-ng-c3383274413=""
            className="row g-3 align-items-end mb-4"
          >
            <div _ngcontent-ng-c3383274413="" className="col-lg-3 col-md-6">
              <div
                _ngcontent-ng-c3383274413=""
                className="input_block custom_select_block position-relative"
              >
                <label _ngcontent-ng-c3383274413="" htmlFor="">
                  {userType}
                </label>
                <mat-form-field
                  _ngcontent-ng-c3383274413=""
                  appearance="outline"
                  className="mat-mdc-form-field ng-tns-c1607617776-0 mat-mdc-form-field-type-mat-select mat-form-field-appearance-outline mat-primary ng-star-inserted"
                >
                  {/**/}
                  <div className="mat-mdc-text-field-wrapper mdc-text-field ng-tns-c1607617776-0 mdc-text-field--outlined mdc-text-field--no-label">
                    {/**/}
                    <div className="mat-mdc-form-field-flex ng-tns-c1607617776-0">
                      <div
                        matformfieldnotchedoutline=""
                        className="mdc-notched-outline ng-tns-c1607617776-0 mdc-notched-outline--no-label ng-star-inserted mdc-notched-outline--notched"
                      >
                        <div className="mdc-notched-outline__leading" />
                        <div
                          className="mdc-notched-outline__notch"
                          style={{ width: 0 }}
                        >
                          {/**/}
                          {/**/}
                          {/**/}
                        </div>
                        <div className="mdc-notched-outline__trailing" />
                      </div>
                      {/**/}
                      {/**/}
                      {/**/}
                      <div className="mat-mdc-form-field-infix ng-tns-c1607617776-0">
                        {/**/}
                        <ReactSelect
                          onChange={(e) => {
                            setSelectedUser(e);
                          }}
                          options={allClients}
                        />
                      </div>
                      {/**/}
                      {/**/}
                    </div>
                    {/**/}
                  </div>
                  <div className="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align ng-tns-c1607617776-0">
                    {/**/}
                    <div
                      className="mat-mdc-form-field-hint-wrapper ng-tns-c1607617776-0 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                      style={{ opacity: 1, transform: "translateY(0%)" }}
                    >
                      {/**/}
                      <div className="mat-mdc-form-field-hint-spacer ng-tns-c1607617776-0" />
                    </div>
                    {/**/}
                  </div>
                </mat-form-field>
                {/**/}
              </div>
            </div>
            <div _ngcontent-ng-c3383274413="" className="col-lg-3 col-md-6">
              <div _ngcontent-ng-c3383274413="" className="input_block">
                <label _ngcontent-ng-c3383274413="">Date </label>
                <input
                  _ngcontent-ng-c3383274413=""
                  type="date"
                  value={moment(payload.startDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setPayload({
                      startDate: moment(e.target.value).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      endDate: moment(e.target.value)
                        .add(7, "d")
                        .format("YYYY-MM-DD HH:mm:ss"),
                    });
                  }}
                  id="name"
                  name="name"
                  className="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c3383274413=""
              className="col-lg-3 col-md-6 col-12"
            >
              <div _ngcontent-ng-c3383274413="" className="input_block">
                <button
                  onClick={() => {
                    getCollectionReport();
                  }}
                  disabled={selectedUser === null}
                  _ngcontent-ng-c3383274413=""
                  type="button"
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <div _ngcontent-ng-c3383274413="" className="row g-3">
            <div
              _ngcontent-ng-c3383274413=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div
                _ngcontent-ng-c3383274413=""
                className="custom_table lena_table"
              >
                <div
                  _ngcontent-ng-c3383274413=""
                  className="table-responsive"
                >
                  <table _ngcontent-ng-c3383274413="" className="table">
                    <thead _ngcontent-ng-c3383274413="">
                      <tr
                        style={{
                          backgroundColor: "green",
                        }}
                        bg="green"
                      >
                        <th _ngcontent-ng-c3383274413="">Agent Code</th>
                        <th _ngcontent-ng-c3383274413="">Agent Name</th>
                        <th _ngcontent-ng-c3383274413="">Open. Bal.</th>
                        <th _ngcontent-ng-c3383274413="">Curr. Bal.</th>
                        <th _ngcontent-ng-c3383274413="">Cls. Bal.</th>
                      </tr>
                    </thead>
                    <tbody
                      _ngcontent-ng-c3383274413=""
                      className="ng-star-inserted"
                    >
                      <tr
                        _ngcontent-ng-c3383274413=""
                        className="bd-blue-100"
                      >
                        <td _ngcontent-ng-c3383274413="" colSpan={5}>
                          <b _ngcontent-ng-c3383274413="">
                            PAYMENT RECEIVING FROM (LENA HE)
                          </b>
                        </td>
                      </tr>
                      {collectionReportMapping?.lenaHai?.map((item) => (
                        <tr
                          _ngcontent-ng-c3383274413=""
                          className="ng-star-inserted"
                        >
                          <td _ngcontent-ng-c3383274413="">
                            {item?.userName?.split(" ")[0]}{" "}
                            <br _ngcontent-ng-c3383274413="" />
                          </td>
                          <td _ngcontent-ng-c3383274413="">
                            {item?.userName
                              .split(" ")
                              .slice(1)
                              .join(" ")}{" "}
                            <br _ngcontent-ng-c3383274413="" />
                          </td>
                          <td _ngcontent-ng-c3383274413="">
                            <span _ngcontent-ng-c3383274413="" className="">
                              0.00
                            </span>
                          </td>
                          <td _ngcontent-ng-c3383274413="">
                            <span _ngcontent-ng-c3383274413=""> 0.00 </span>
                          </td>
                          <td _ngcontent-ng-c3383274413="">
                            <span
                              _ngcontent-ng-c3383274413=""
                              className={
                                item?.balance < 0
                                  ? "mainus_value"
                                  : "plus_value"
                              }
                            >
                              <strong _ngcontent-ng-c3383274413="">
                                {parseFloat(item?.balance).toFixed(2)}
                              </strong>
                            </span>
                          </td>
                        </tr>
                      ))}
                      {/**/}
                      {/**/}
                    </tbody>
                    {/**/}
                    <tfoot
                      _ngcontent-ng-c3383274413=""
                      className="ng-star-inserted"
                    >
                      <tr _ngcontent-ng-c3383274413="">
                        <td _ngcontent-ng-c3383274413="" colSpan={2}>
                          Total
                        </td>
                        <td _ngcontent-ng-c3383274413="">
                          <span
                            _ngcontent-ng-c3383274413=""
                            className="plus_value"
                          >
                            0.00
                          </span>
                        </td>
                        <td _ngcontent-ng-c3383274413="">
                          <span _ngcontent-ng-c3383274413=""> 0.00 </span>
                        </td>
                        <td _ngcontent-ng-c3383274413="">
                          <span
                            _ngcontent-ng-c3383274413=""
                            className={
                              collectionReportMapping?.lenaHai?.reduce(
                                (acc, item) => acc + item?.balance,
                                0
                              ) < 0
                                ? "mainus_value"
                                : "plus_value"
                            }
                          >
                            <strong _ngcontent-ng-c3383274413="">
                              {parseFloat(
                                collectionReportMapping?.lenaHai?.reduce(
                                  (acc, item) => acc + item?.balance,
                                  0
                                )
                              ).toFixed(2)}
                            </strong>
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                    {/**/}
                    {/**/}
                  </table>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c3383274413=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div
                _ngcontent-ng-c3383274413=""
                className="custom_table dena_table"
              >
                <div
                  _ngcontent-ng-c3383274413=""
                  className="table-responsive"
                >
                  <table _ngcontent-ng-c3383274413="" className="table">
                    <thead _ngcontent-ng-c3383274413="">
                      <tr
                        style={{
                          backgroundColor: "red !important",
                        }}
                        bg="red"
                        className="bg-danger"
                      >
                        <th _ngcontent-ng-c3383274413="">Agent Code</th>
                        <th _ngcontent-ng-c3383274413="">Agent Name</th>
                        <th _ngcontent-ng-c3383274413="">Open. Bal.</th>
                        <th _ngcontent-ng-c3383274413="">Curr. Bal.</th>
                        <th _ngcontent-ng-c3383274413="">Cls. Bal.</th>
                      </tr>
                    </thead>
                    <tbody _ngcontent-ng-c3383274413="">
                      <tr
                        _ngcontent-ng-c3383274413=""
                        className="bd-blue-100"
                      >
                        <td _ngcontent-ng-c3383274413="" colSpan={5}>
                          <b _ngcontent-ng-c3383274413="">
                            PAYMENT RECEIVING FROM (DENA HE)
                          </b>
                        </td>
                      </tr>
                      {collectionReportMapping?.denaHai?.length > 0 ? (
                        collectionReportMapping?.denaHai?.map((item) => (
                          <tr
                            _ngcontent-ng-c3383274413=""
                            className="ng-star-inserted"
                          >
                            <td _ngcontent-ng-c3383274413="">
                              {item?.userName?.split(" ")[0]}{" "}
                              <br _ngcontent-ng-c3383274413="" />
                            </td>
                            <td _ngcontent-ng-c3383274413="">
                              {item?.userName?.split(" ").slice(1)}{" "}
                              <br _ngcontent-ng-c3383274413="" />
                            </td>
                            <td _ngcontent-ng-c3383274413="">
                              <span _ngcontent-ng-c3383274413="" className="">
                                0.00
                              </span>
                            </td>
                            <td _ngcontent-ng-c3383274413="">
                              <span _ngcontent-ng-c3383274413=""> 0.00 </span>
                            </td>
                            <td _ngcontent-ng-c3383274413="">
                              <span
                                _ngcontent-ng-c3383274413=""
                                className={
                                  item?.balance < 0
                                    ? "mainus_value"
                                    : "plus_value"
                                }
                              >
                                <strong _ngcontent-ng-c3383274413="">
                                  {parseFloat(item?.balance).toFixed(2)}
                                </strong>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr
                          _ngcontent-ng-c3383274413=""
                          className="ng-star-inserted"
                        >
                          <td
                            _ngcontent-ng-c3383274413=""
                            colSpan={4}
                            style={{ textAlign: "center" }}
                          >
                            No Record Found!
                          </td>
                        </tr>
                      )}

                      {/**/}
                      {/**/}
                    </tbody>
                    {/**/}
                    <tfoot
                      _ngcontent-ng-c3383274413=""
                      className="ng-star-inserted"
                    >
                      <tr _ngcontent-ng-c3383274413="">
                        <td _ngcontent-ng-c3383274413="" colSpan={2}>
                          Total
                        </td>
                        <td _ngcontent-ng-c3383274413="">
                          <span
                            _ngcontent-ng-c3383274413=""
                            className="plus_value"
                          >
                            0.00
                          </span>
                        </td>
                        <td _ngcontent-ng-c3383274413="">
                          <span _ngcontent-ng-c3383274413=""> 0.00 </span>
                        </td>
                        <td _ngcontent-ng-c3383274413="">
                          <span
                            _ngcontent-ng-c3383274413=""
                            className={
                              collectionReportMapping?.denaHai?.reduce(
                                (acc, item) => acc + item?.balance,
                                0
                              ) < 0
                                ? "mainus_value"
                                : "plus_value"
                            }
                          >
                            <strong _ngcontent-ng-c3383274413="">
                              {parseFloat(
                                collectionReportMapping?.denaHai?.reduce(
                                  (acc, item) => acc + item?.balance,
                                  0
                                )
                              ).toFixed(2)}
                            </strong>
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-agentledger>
      {/**/}
    </div>
  );
}
