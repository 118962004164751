import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ChangePassword from "./pages/ChangePassword";

import { useDispatch, useSelector } from "react-redux";
import store, { saveState } from "./redux/store";
import { Toaster } from "react-hot-toast";
import Sidebar from "./pages/components/Sidebar";
import MyClients from "./pages/MyClients";
import CreateClient from "./pages/CreateClient";
import EditClient from "./pages/EditClient";
import Header from "./pages/components/Header";
import CollectionReport from "./pages/CollectionReport";
import MyLedgers from "./pages/MyLedgers";
import ProfitLoss from "./pages/ProfitLoss";
import Matches from "./pages/Matches";
import ClientDashboard from "./pages/ClientDashboard";
import ReceiveCash from "./pages/ReceiveCash";
import PayCash from "./pages/PayCash";
import Ledger from "./pages/Ledger";
import CurrentBets from "./pages/CurrentBets";
import CricketMatch from "./pages/CricketMatch";
import ClientChangePassword from "./pages/ClientChangePassword";
import BetHistory from "./pages/BetHistory";
import GeneralReport from "./pages/GeneralReport";
import ClientChangelog from "./pages/ClientChangelog";
import MatchDashboard from "./pages/MatchDashboard";
import MatchBetSlip from "./pages/MatchBetSlip";
import { QueryClient, QueryClientProvider } from "react-query";
import ClientLimitUpdate from "./pages/ClientLimitUpdate";
import Language from "./pages/Language";
import { ChakraProvider } from "@chakra-ui/react";
import MatchCurrentBets from "./pages/MatchCurrentBets";
import InPlayList from "./pages/Inplay";
import MatchPosition from "./pages/MatchPosition";
import SessionPlusMinusSelect from "./pages/SessionPlusMinusSelect";
import DisplayMatchSession from "./pages/DisplayMatchSession";
import ViewSessionReport from "./pages/ViewSessionReport";
import ViewMatchReport from "./pages/ViewMatchReport";
import { ChangeClientLimitAPI } from "./service/UserService";
import ChildLimitUpdate from "./pages/ChildLimitUpdate";
import DebitCreditEntry from "./pages/DebitCreditEntry";
import MatchSessionPlusMinusDisplay from "./pages/MatchSessionPlusMinusDisplay";
import LoginReport from "./pages/LoginReport";
import ClientPlusMinus from "./pages/ClientPlusMinus";
import Profile from "./pages/Profile";

import BookMakersBook from "./pages/components/BookMakerBook";
import AccountStatement from "./pages/AccountStatement";
import ComissionLenaDena from "./pages/ComissionLenaDena";
import CommissionLenaDenaUser from "./pages/CommissionLenaDenaUser";
import CommissionLenaDenaHistory from "./pages/CommissionLenaDenaHistory";
import { APP_NAME } from "./utils/constants";
import CasinoDetails from "./pages/casino/CasinoDetails";
import CasinoBetReport from "./pages/casino/CasinoBetReport";
import MatchSessionPlusMinusDisplayCasino from "./pages/MatchSessionPlusMinusDisplayCasino";
import CasinoPosition from "./pages/casino/CasinoPosition";
import MatkaInplay from "./pages/matka/MatkaInplay";
import GeneralLedger from "./pages/GeneralLedger";
import "./App.css";
import MatchSessionPlusMinusDisplayNew from "./pages/MatchSessionPlusMinusDisplayNew";
const queryClient = new QueryClient();
export default function App() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const [isFolded, setIsFolded] = useState(false);
  const location = useLocation();

  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, []);

  const isMobile = window.innerWidth < 768;
  return (
    <ChakraProvider>
      <div id="wrapper" className="text-sm">
        <Toaster />
        <QueryClientProvider client={queryClient}>
          {userData?.token ? (
            <div id="app" className={`app ${isFolded ? "is-folded" : ""}`}>
              <div id="unfolded " className="dashBoard_overLay" />
              <div className="layout">
                <Header
                  isSidebarOpen={isFolded}
                  setIsSidebarOpen={setIsFolded}
                />

                <Sidebar isOpen={isFolded} setIsOpen={setIsFolded} />

                <div className="page_container">
                  <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route
                      exact
                      path="/agent/dashboard/language"
                      element={<Language />}
                    />
                    <Route
                      exact
                      path="/agent/matches/:id/bet_slips"
                      element={<MatchBetSlip />}
                    />
                    <Route
                      exact
                      path="/agent/dashboard"
                      element={<Dashboard active="dashboard" />}
                    />
                    <Route
                      path="/password/dashboard/manage_password_panel"
                      element={<ChangePassword />}
                    />

                    <Route path="/report/login/" element={<LoginReport />} />
                    <Route
                      path="/report/bet_history"
                      element={<BetHistory />}
                    />
                    <Route
                      path="/report/general_report"
                      element={<GeneralReport />}
                    />
                    <Route
                      path="/:userType/children/:userName"
                      element={<MyClients />}
                    />
                    <Route
                      path="/ledger/commission-lena-dena"
                      element={<ComissionLenaDena />}
                    />
                    <Route
                      path="/user/:userType/:id/change_password_panel"
                      element={<ClientChangePassword />}
                    />
                    <Route
                      path="/agent/ledgers/collection_report"
                      element={<CollectionReport />}
                    />
                    <Route
                      path="/casino/casino-details"
                      element={<CasinoDetails />}
                    />
                    <Route
                      path="/casino/casino-position/:id"
                      element={<CasinoPosition />}
                    />
                    <Route
                      path="/casino/casino-select-plus-minus-report/:id"
                      element={<SessionPlusMinusSelect isCasino={true} />}
                    />

                    <Route
                      path="/casino/casino-bet-report"
                      element={<CasinoBetReport />}
                    />

                    <Route
                      exact
                      path="/agent/:usertype/:id"
                      element={<ClientDashboard />}
                    />
                    <Route exact path="/inplay" element={<InPlayList />} />
                    <Route
                      exact
                      path="/agent/:usertype/:id/receive_cash_from_user_panel"
                      element={<ReceiveCash />}
                    />
                    <Route
                      exact
                      path="/agent/:usertype/:id/changelog"
                      element={<ClientChangelog />}
                    />
                    <Route
                      exact
                      path="/ledger"
                      element={<Ledger isCashLedger={true} />}
                    />
                    <Route
                      path="/game/i/MatchSessionPlusMinusDisplay/:id"
                      element={<MatchSessionPlusMinusDisplay />}
                    />
                    <Route
                      path="/game/i/MatchSessionPlusMinusDisplay/new/:id"
                      element={<MatchSessionPlusMinusDisplayNew />}
                    />
                    <Route
                      path="/game/i/MatchSessionPlusMinusDisplay/casino/:id"
                      element={
                        <MatchSessionPlusMinusDisplayCasino isCasino={true} />
                      }
                    />

                    <Route
                      path="/ledger/client/pm"
                      element={<ClientPlusMinus />}
                    />

                    <Route
                      exact
                      path="/ledger/:userType"
                      element={<GeneralLedger />}
                    />
                    <Route
                      exact
                      path="/agent/matches/dashboard/:gametype/:id"
                      element={<MatchDashboard />}
                    />
                    <Route
                      exact
                      path="/agent/:usertype/:id/pay_cash_to_user_panel"
                      element={<PayCash />}
                    />
                    <Route
                      exact
                      path="/agent/matches/:id/live_report12"
                      element={<CricketMatch />}
                    />
                    <Route
                      exact
                      path="/report/current_bets"
                      element={<CurrentBets />}
                    />
                    <Route
                      exact
                      path="/agent/commission_lena_dena_report"
                      element={<ComissionLenaDena />}
                    />
                    <Route
                      exact
                      path="/agent/:usertype/:id/ledger_show"
                      element={<Ledger />}
                    />
                    <Route path="/game/:gameType" element={<Matches />} />
                    <Route
                      path="/game/matchPosition/:id"
                      element={<MatchPosition />}
                    />
                    <Route
                      path="/game/matchPosition/:id/:runnerName/:marketId"
                      element={<MatchPosition />}
                    />
                    <Route
                      path="/game/sessionPlusMinusSelect/:id"
                      element={<SessionPlusMinusSelect />}
                    />
                    <Route
                      path="/game/displayMatchSession/:id"
                      element={<DisplayMatchSession />}
                    />
                    <Route
                      path="/game/viewSessionReport/:id"
                      element={<ViewSessionReport />}
                    />
                    <Route
                      path="/game/viewMatchReport/:id"
                      element={<ViewMatchReport />}
                    />
                    <Route path="/game/inPlay" element={<InPlayList />} />
                    <Route
                      path="/game/completeGame"
                      element={<InPlayList completeGame={true} />}
                    />
                    <Route
                      path="/game/i/:id/viewMatchReport"
                      element={<ViewMatchReport />}
                    />
                    <Route
                      path="/game/i/:id/viewSessionReport"
                      element={<ViewSessionReport />}
                    />
                    <Route
                      path="/ct/:userType"
                      element={<DebitCreditEntry />}
                    />
                    <Route
                      path="/agent/ledgers/earning_report"
                      element={<ProfitLoss />}
                    />
                    <Route path="/agent/ledgers" element={<Ledger />} />
                    <Route path="/:userType" element={<MyClients />} />
                    <Route
                      exact
                      path="/update/limit/:userType"
                      element={<ChildLimitUpdate />}
                    />
                    <Route
                      exact
                      path="/update_limit/:userType"
                      element={<ClientLimitUpdate />}
                    />
                    <Route
                      exact
                      path="/create/new/:usertype"
                      element={<CreateClient />}
                    />
                    <Route
                      exact
                      path="/edit/:id/:userType"
                      element={<EditClient />}
                    />
                    <Route
                      path="/setting/change-password/:id"
                      element={<ClientChangePassword />}
                    />
                    <Route
                      path="/match/:id/bookmaker_book"
                      element={<BookMakersBook />}
                    />
                    <Route
                      path="/report/account_statement"
                      element={<AccountStatement />}
                    />
                    <Route
                      path="/report/account_statement/:id"
                      element={<AccountStatement />}
                    />
                    <Route path="/matka/inplay" element={<MatkaInplay />} />
                    <Route
                      path="/matka/complete"
                      element={<MatkaInplay completeGame={true} />}
                    />
                    <Route
                      path="/agent/dashboard/matches/:id/current_bets"
                      element={<MatchCurrentBets />}
                    />
                    <Route
                      path="/agent/dashboard/manage_password_panel"
                      element={<ChangePassword active="change_password" />}
                    />
                    <Route
                      path="/changePassword"
                      element={<ChangePassword />}
                    />
                    <Route
                      path="/agent/commission_lena_dena_user_report/:id"
                      element={<CommissionLenaDenaUser />}
                    />
                    <Route
                      path="/agent/commission_lena_dena_user_report_history/:id"
                      element={<CommissionLenaDenaHistory />}
                    />
                    <Route path="/profile" element={<Profile />} />
                  </Routes>
                </div>
              </div>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Login />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          )}
        </QueryClientProvider>
        <footer class="main-footer">
          <strong>
            Copyright © 2024{" "}
            <a href="#" id="siteName">
              {APP_NAME}
            </a>
            .
          </strong>
          All rights reserved.
          <div class="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.0.1
          </div>
        </footer>
      </div>
    </ChakraProvider>
  );
}
