import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  addNewClientAPI,
  getGeneratedUsernameAPI,
  getUserDataAPI,
  getChildUserInfoAPI,
  getUsersWithUserTypeAPI,
} from "../service/UserService";
import { Encrypt, PLATFORM } from "../utils/constants";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactSelect from "react-select";

export default function CreateClient() {
  const [clientData, setClientData] = useState({});
  const location = useLocation();
  const [globalSetting, setGlobalSetting] = useState({});
  const [payload, setPayload] = useState({
    matchCommission: 2,
    sessionCommission: 3,
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [done, setDone] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const userType = useParams()?.usertype;
  const [userTypeList, setUserTypeList] = useState();
  const [selectedParent, setSelectedParent] = useState(
    userType?.toUpperCase() == "ADMIN"
      ? {
          value: userData?.uid,
          label: userData?.username,
        }
      : null
  );
  const [isCommission, setIsCommission] = useState("yes");
  const [isCasinoCommission, setIsCasinoCommission] = useState("yes");
  const [isMatkaCommission, setIsMatkaCommission] = useState("no");
  const getDefaultGeneratedUsername = async () => {
    const { response } = await getGeneratedUsernameAPI(userType);
    setPayload({ ...payload, username: response.username });
  };
  const getUsersWithUserType = async () => {
    let userTypeGet = userType;
    if (userType?.toUpperCase() === "SUB_ADMIN") {
      userTypeGet = "ADMIN";
    } else if (userType?.toUpperCase() === "MASTER") {
      userTypeGet = "SUB_ADMIN";
    } else if (userType?.toUpperCase() === "SUPER_AGENT") {
      userTypeGet = "MASTER";
    } else if (userType?.toUpperCase() === "AGENT") {
      userTypeGet = "SUPER_AGENT";
    } else if (userType?.toUpperCase() === "CLIENT") {
      userTypeGet = "AGENT";
    }

    const { response } = await getUsersWithUserTypeAPI(userTypeGet);
    let response_ = response;
    if (response_) {
      setUserTypeList(response_);
    }
  };
  const getChildUserInfo = async () => {
    const { response } = await getChildUserInfoAPI(selectedParent?.value);
    // console.log(response?.childInfo.balance);
    setClientData((prevState) => ({
      ...prevState,
      partnershipAndCommission: {
        ...prevState.partnershipAndCommission,
        casinoCommission: response?.childInfo?.casinoCommission,
        sessionCommission: response?.childInfo?.sessionCommission,
        matchCommission: response?.childInfo?.matchCommission,
        myCasinoPartnership: response?.childInfo?.myCasinoPartnership,
        myPartnership: response?.childInfo?.myPartnership,
        balance: response?.childInfo?.balance,
      },
    }));
  };

  useEffect(() => {
    getUsersWithUserType();
    if (selectedParent?.value && selectedParent?.value !== userData?.uid) {
      getChildUserInfo();
    } else {
      getUserInfo();
    }
  }, [selectedParent?.value]);

  const getUserInfo = async () => {
    const { response } = await getUserDataAPI();
    setIsAdmin(false);
    setClientData(response);
  };

  const handleSubmit = async () => {
    let filteredPayload = {
      ...payload,
    };
    if (!payload.fullName) {
      toast.error(`Please fill in the Name field.`);
      return;
    }

    if (!payload.balance) {
      filteredPayload.balance = 0;
    }

    if (!payload.myPartnership && !userType?.toUpperCase() == "CLIENT") {
      toast.error(`Please fill in the ${userType} Share field.`);
      return;
    }
    if (!payload.myCasinoPartnership && !userType?.toUpperCase() == "CLIENT") {
      toast.error(`Please fill in the ${userType} Casino Share field.`);
      return;
    }

    if (userType.toUpperCase() == "ADMIN") {
      if (filteredPayload.balance > userData?.balance) {
        toast.error("Limit can not be greater than your partnership");
        return;
      }
    } else {
      if (
        filteredPayload.balance > clientData?.partnershipAndCommission?.balance
      ) {
        toast.error("Limit can not be greater than your partnership");
        return;
      }
    }
    if (
      filteredPayload.matchCommission >
      clientData?.partnershipAndCommission?.matchCommission
    ) {
      setPayload({
        ...payload,
        matchCommission: "",
      });
      toast.error("Match Commission can not be greater than your partnership");
      return;
    }

    if (
      filteredPayload.sessionCommission >
      clientData?.partnershipAndCommission?.sessionCommission
    ) {
      setPayload({
        ...payload,
        sessionCommission: "",
      });
      toast.error(
        "Session Commission can not be greater than your partnership"
      );
      return;
    }

    if (
      filteredPayload.casinoCommission >
      clientData?.partnershipAndCommission?.casinoCommission
    ) {
      setPayload({
        ...payload,
        casinoCommission: "",
      });
      toast.error("Casino Commission can not be greater than your partnership");
      return;
    }
    if (
      filteredPayload.myCasinoPartnership >
      clientData?.partnershipAndCommission?.myCasinoPartnership
    ) {
      setPayload({
        ...payload,
        myCasinoPartnership: "",
      });
      toast.error("Casino Share can not be greater than your partnership");
      return;
    }
    delete filteredPayload["confirmPassword"];
    var pass = generatePassword();
    filteredPayload.password = Encrypt(pass);
    setPayload({
      ...payload,
      password: pass,
    });
    filteredPayload.userType = userType.toUpperCase();
    filteredPayload.parentUserId = selectedParent?.value;
    // filteredPayload.parentUserId = clientData?.uid;
    filteredPayload.parentCasinoPartnership =
      clientData?.partnershipAndCommission?.myCasinoPartnership;
    if (userType?.toUpperCase() == "CLIENT") {
      filteredPayload.parentPartnership = payload.myShare;
    }
    if (filteredPayload?.parentUserId == userData?.uid) {
      delete filteredPayload["parentUserId"];
    }
    try {
      const { response, code, error, status } = await addNewClientAPI(
        filteredPayload
      );
      if (code === 200) {
        toast.success(response?.status || "User Added Successfully");

        setDone(true);
      } else if (code !== 200 && status) {
        toast.error(
          status || "Something went wrong Please check all you field"
        );
      } else {
        toast.error(
          error.description || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
          confirmPassword: "",
        });
      }
    } catch (err) {
      setPayload({});
      toast.error(
        err.message || "Something went wrong Please check all you field"
      );
    }
  };

  const handleCommissionChange = (e) => {
    const commissionOn = e.target.value == "true";

    setPayload({
      ...payload,
      commissionOn,
    });
    const defaultValues = commissionOn
      ? {
          matchCommission:
            clientData?.partnershipAndCommission.matchCommission || 0,
          sessionCommission:
            clientData?.partnershipAndCommission.sessionCommission || 0,
          casinoCommission:
            clientData?.partnershipAndCommission.casinoCommission || 0,
        }
      : {
          matchCommission: 0,
          sessionCommission: 0,
          casinoCommission: 0,
        };
    setPayload((prevState) => ({
      ...prevState,
      ...defaultValues,
      commissionOn: commissionOn ? commissionOn : "false",
    }));
  };

  useEffect(() => {
    setClientData({
      partnershipAndCommission: {
        matchCommission: 2,
        sessionCommission: 3,
        casinoCommission: 0,
      },
    });
  }, []); // Runs only once on component mount

  useEffect(() => {
    getDefaultGeneratedUsername();
  }, []);

  const generatePassword = () => {
    const passwordLength = 6;
    let newPassword = "";
    for (let i = 0; i < passwordLength; i++) {
      newPassword += Math.floor(Math.random() * 10); // Generate a random number between 0 and 9
    }
    return newPassword;
  };
  const shareDetails = (client) => {
    let url = `https://api.whatsapp.com/send?text=${client?.username}\n ${
      client?.password
    }\n ${
      userType?.toLowerCase() == "client"
        ? `\n${process.env?.REACT_APP_NAME}.com`
        : `\n${process.env?.REACT_APP_NAME}.com/admin`
    }`;
    window.open(url, "_blank");
  };

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      {done && (
        <div
          _ngcontent-ng-c420556888=""
          id="detailaModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          className="sitemoadal modal fade show"
          aria-modal="true"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            _ngcontent-ng-c420556888=""
            className="modal-dialog modal-dialog-centered"
          >
            <div _ngcontent-ng-c420556888="" className="modal-content">
              <div _ngcontent-ng-c420556888="" className="modal-header">
                <h5
                  _ngcontent-ng-c420556888=""
                  id="exampleModalLabel"
                  className="modal-title"
                >
                  User Deatils
                </h5>
                <button
                  _ngcontent-ng-c420556888=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    window.location.href = `/${userType}`;
                  }}
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c420556888="" className="modal-body">
                <div _ngcontent-ng-c420556888="" className="bet-slip-box">
                  <div _ngcontent-ng-c420556888="" className="bet-slip">
                    <div _ngcontent-ng-c420556888="" className="bet-nation">
                      <span _ngcontent-ng-c420556888="">
                        UserName : {payload?.username}
                      </span>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="match-result"
                    />
                  </div>
                  <div _ngcontent-ng-c420556888="" className="bet-slip">
                    <div _ngcontent-ng-c420556888="" className="bet-nation">
                      <span _ngcontent-ng-c420556888="">
                        password : {payload?.password}{" "}
                      </span>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="match-result"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c420556888=""
                    className="place-bet-btn mt-4 text-align"
                  >
                    <button
                      onClick={() => shareDetails(payload)}
                      _ngcontent-ng-c420556888=""
                      className="btn btn-primary btn-block"
                    >
                      <span _ngcontent-ng-c420556888="">
                        Share on WhatsApp
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-create-user _nghost-ng-c420556888="" className="ng-star-inserted">
        <div _ngcontent-ng-c420556888="" className="page_header">
          <h1 _ngcontent-ng-c420556888="" className="page_heading">
            Create New {userType}
          </h1>
          <div _ngcontent-ng-c420556888="" className="breadcrumb_block">
            <nav _ngcontent-ng-c420556888="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c420556888="" className="breadcrumb">
                <li _ngcontent-ng-c420556888="" className="breadcrumb-item">
                  <a _ngcontent-ng-c420556888="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c420556888=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Create New {userType}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c420556888=""
          className="Super_master_details_section"
        >
          <div _ngcontent-ng-c420556888="" className="jmd_input">
            <form
              _ngcontent-ng-c420556888=""
              noValidate=""
              className="ng-untouched ng-pristine ng-invalid"
            >
              <div _ngcontent-ng-c420556888="" className="row">
                {
                  <div
                    _ngcontent-ng-c420556888=""
                    className="col-md-3 col-6 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c420556888=""
                      className="clearfix_input position-relative custom_select_block"
                    >
                      <label
                        _ngcontent-ng-c420556888=""
                        htmlFor=""
                        className="d-block ng-star-inserted"
                      >
                        Client
                      </label>
                      <mat-form-field
                        _ngcontent-ng-c420556888=""
                        appearance="outline"
                        className="mat-mdc-form-field ng-tns-c1607617776-0 mat-mdc-form-field-type-mat-select mat-form-field-appearance-outline mat-primary ng-star-inserted"
                      >
                        {/**/}
                        <div className="mat-mdc-text-field-wrapper mdc-text-field ng-tns-c1607617776-0 mdc-text-field--outlined mdc-text-field--no-label">
                          {/**/}
                          <div className="mat-mdc-form-field-flex ng-tns-c1607617776-0">
                            <div
                              matformfieldnotchedoutline=""
                              className="mdc-notched-outline ng-tns-c1607617776-0 mdc-notched-outline--no-label ng-star-inserted"
                            >
                              <div className="mdc-notched-outline__leading" />
                              <div className="mdc-notched-outline__notch">
                                {/**/}
                                {/**/}
                                {/**/}
                              </div>
                              <div className="mdc-notched-outline__trailing" />
                            </div>
                            {/**/}
                            {/**/}
                            {/**/}
                            <div className="mat-mdc-form-field-infix ng-tns-c1607617776-0">
                              {/**/}
                              <ReactSelect
                                _ngcontent-ng-c420556888=""
                                role="combobox"
                                aria-autocomplete="none"
                                aria-haspopup="listbox"
                                name="Sport_id"
                                className="mat-mdc-select ng-tns-c2250287185-1 ng-tns-c1607617776-0 mat-mdc-select-empty ng-star-inserted"
                                aria-labelledby="mat-select-value-1"
                                id="mat-select-0"
                                tabIndex={0}
                                aria-expanded="false"
                                value={
                                  userType?.toUpperCase() == "ADMIN"
                                    ? {
                                        value: userData?.uid,
                                        label: userData?.username,
                                      }
                                    : selectedParent
                                }
                                isDisabled={
                                  userType?.toUpperCase() == "ADMIN"
                                }
                                aria-required="false"
                                aria-disabled="false"
                                onChange={(value) => {
                                  setSelectedParent(value);
                                }}
                                aria-invalid="false"
                                options={userTypeList?.users
                                  ?.sort((a, b) => a.userId - b.userId)
                                  ?.map((item, index) => {
                                    return {
                                      value: item.userId,
                                      label: item.userName + "-" + item?.name,
                                    };
                                  })}
                              />
                            </div>
                            {/**/}
                            {/**/}
                          </div>
                          {/**/}
                        </div>
                        <div className="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align ng-tns-c1607617776-0">
                          {/**/}
                          <div
                            className="mat-mdc-form-field-hint-wrapper ng-tns-c1607617776-0 ng-trigger ng-trigger-transitionMessages ng-star-inserted"
                            style={{
                              opacity: 1,
                              transform: "translateY(0%)",
                            }}
                          >
                            {/**/}
                            <div className="mat-mdc-form-field-hint-spacer ng-tns-c1607617776-0" />
                          </div>
                          {/**/}
                        </div>
                      </mat-form-field>
                      {/**/}
                      {/**/}
                      {/**/}
                    </div>
                  </div>
                }
                {/**/}
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Name
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      formcontrolname="name"
                      type="text"
                      value={payload?.fullName}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          fullName: e.target.value,
                        })
                      }
                      id="name"
                      name="name"
                      className="form-control ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Reference
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      type="text"
                      formcontrolname="reference"
                      id="name"
                      name="name"
                      value={payload?.reference}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          reference: e.target.value,
                        })
                      }
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      {userType} Limit
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      formcontrolname="free_chip"
                      type="text"
                      id="name"
                      name="name"
                      value={payload?.balance}
                      onChange={(e) => {
                        if (
                          (userData?.userType === "SUPER_ADMIN" &&
                            userType.toUpperCase() === "ADMIN") ||
                          (userData?.userType === "ADMIN" &&
                            userType.toUpperCase() === "SUB_ADMIN") ||
                          (userData?.userType === "SUB_ADMIN" &&
                            userType.toUpperCase() === "MASTER") ||
                          (userData?.userType === "MASTER" &&
                            userType.toUpperCase() === "SUPER_AGENT") ||
                          (userData?.userType === "SUPER_AGENT" &&
                            userType.toUpperCase() === "AGENT") ||
                          (userData?.userType === "AGENT" &&
                            userType.toUpperCase() === "CLIENT")
                            ? userData?.balance - e.target.value < 0
                            : clientData?.partnershipAndCommission?.balance -
                                e.target.value <
                              0
                        ) {
                          toast.error(
                            "Limit can not be greater than your partnership"
                          );
                          return;
                        }
                        setPayload({
                          ...payload,
                          balance: e.target.value,
                        });
                      }}
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </div>
              <div _ngcontent-ng-c420556888="" className="row">
                <h6
                  _ngcontent-ng-c420556888=""
                  className="input-sub-headding"
                >
                  My Limit
                </h6>
                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Rem Limit
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      readOnly=""
                      type="text"
                      placeholder={0}
                      formcontrolname="remainingLimit"
                      id="name"
                      name="name"
                      disabled
                      value={
                        (userData?.userType === "SUPER_ADMIN" &&
                          userType.toUpperCase() === "ADMIN") ||
                        (userData?.userType === "ADMIN" &&
                          userType.toUpperCase() === "SUB_ADMIN") ||
                        (userData?.userType === "SUB_ADMIN" &&
                          userType.toUpperCase() === "MASTER") ||
                        (userData?.userType === "MASTER" &&
                          userType.toUpperCase() === "SUPER_AGENT") ||
                        (userData?.userType === "SUPER_AGENT" &&
                          userType.toUpperCase() === "AGENT") ||
                        (userData?.userType === "AGENT" &&
                          userType.toUpperCase() === "CLIENT")
                          ? userData?.balance -
                            (payload?.balance ? payload?.balance : 0)
                          : clientData?.partnershipAndCommission?.balance -
                            (payload?.balance ? payload?.balance : 0)
                      }
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </div>
              {selectedParent && userType?.toLowerCase() != "client" && (
                <div
                  _ngcontent-ng-c420556888=""
                  className="row ng-star-inserted"
                >
                  <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                    <h6
                      _ngcontent-ng-c420556888=""
                      className="input-sub-headding"
                    >
                      {userType} Share{" "}
                    </h6>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="clearfix_input"
                    >
                      <label _ngcontent-ng-c420556888="" htmlFor="">
                        Share %
                      </label>
                      <input
                        _ngcontent-ng-c420556888=""
                        type="text"
                        id="name"
                        name="name"
                        max={
                          clientData?.partnershipAndCommission?.myPartnership
                        }
                        defaultValue={0}
                        min={0.0}
                        value={payload?.myPartnership}
                        onChange={(e) =>
                          setPayload({
                            ...payload,
                            myPartnership: e.target.value,
                          })
                        }
                        readOnly=""
                        className="form-control ng-star-inserted"
                      />
                    </div>
                  </div>
                  <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                    <h6
                      _ngcontent-ng-c420556888=""
                      className="input-sub-headding"
                    >
                      My Share
                    </h6>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="clearfix_input"
                    >
                      <label _ngcontent-ng-c420556888="" htmlFor="">
                        Share %
                      </label>
                      <input
                        _ngcontent-ng-c420556888=""
                        readOnly=""
                        placeholder={0}
                        value={
                          clientData?.partnershipAndCommission
                            ?.myPartnership -
                          (payload?.myPartnership
                            ? payload?.myPartnership
                            : 0)
                        }
                        disabled
                        formcontrolname="parrent_partnership"
                        type="text"
                        id="name"
                        name="name"
                        className="form-control ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div _ngcontent-ng-c420556888="" className="row">
                <h6
                  _nc
                  gcontent-ng-c420556888=""
                  className="input-sub-headding"
                >
                  {userType} Commission
                </h6>
                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Session Commission Type
                    </label>
                    <select
                      _ngcontent-ng-c420556888=""
                      aria-label="Default select example"
                      onChange={(e) => {
                        handleCommissionChange(e);
                        setIsCommission(e.target.value);
                      }}
                      value={
                        payload?.commissionOn
                          ? payload?.commissionOn
                          : clientData?.partnershipAndCommission
                              ?.sessionCommission
                          ? "true"
                          : "false"
                      }
                      formcontrolname="session_commission_type"
                      className="form-select form-control ng-untouched ng-pristine ng-invalid"
                    >
                      <option
                        _ngcontent-ng-c420556888=""
                        value="false"
                        selected=""
                      >
                        Commission Type
                      </option>
                      <option value="false" _ngcontent-ng-c420556888="">
                        No Comm
                      </option>
                      <option value="true" _ngcontent-ng-c420556888="">
                        Bet By Bet
                      </option>
                    </select>
                  </div>
                </div>

                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Match Commission{" "}
                    </label>
                    <input
                      max={
                        clientData?.partnershipAndCommission?.matchCommission
                      }
                      min={0.0}
                      defaultValue={0}
                      _ngcontent-ng-c420556888=""
                      formcontrolname="match_commission"
                      placeholder={0}
                      type="text"
                      disabled={isCommission === "false"}
                      id="name"
                      value={payload?.matchCommission}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          matchCommission: e.target.value,
                        })
                      }
                      name="name"
                      className="form-control disabled ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Session Commission
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      type="text"
                      placeholder={0}
                      disabled={isCommission === "false"}
                      max={
                        clientData?.partnershipAndCommission
                          ?.sessionCommission
                      }
                      min={0.0}
                      defaultValue={0}
                      value={payload?.sessionCommission}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          sessionCommission: e.target.value,
                        })
                      }
                      formcontrolname="session_commission"
                      id="name"
                      name="name"
                      className="form-control disabled ng-untouched ng-pristine ng-invalid"
                    />
                  </div>
                </div>
              </div>
              <div _ngcontent-ng-c420556888="" className="row">
                <h6
                  _ngcontent-ng-c420556888=""
                  className="input-sub-headding"
                >
                  My Commission
                </h6>
                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Session Commission Type
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      type="text"
                      placeholder={0}
                      disabled
                      value={
                        clientData?.partnershipAndCommission
                          ?.sessionCommission ||
                        clientData?.partnershipAndCommission?.matchCommission
                          ? "Bet By Bet"
                          : "No Comm"
                      }
                      name="SASessionCommissionType"
                      formcontrolname="parrent_sessionType"
                      id="SASessionCommissionType"
                      readOnly=""
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Match Commission
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      placeholder={0}
                      type="text"
                      id="name"
                      value={
                        clientData?.partnershipAndCommission?.matchCommission
                      }
                      name="name"
                      formcontrolname="parrent_matchCommission"
                      readOnly=""
                      disabled
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
                <div _ngcontent-ng-c420556888="" className="col-md-3 col-6">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      Session Commission
                    </label>
                    <input
                      _ngcontent-ng-c420556888=""
                      placeholder={0}
                      formcontrolname="parrent_sessionCommission"
                      type="text"
                      id="name"
                      name="name"
                      value={
                        clientData?.partnershipAndCommission
                          ?.sessionCommission
                      }
                      disabled
                      readOnly=""
                      className="form-control ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </div>
              {userType != "client" && (
                <div _ngcontent-ng-c420556888="" className="row">
                  <h6
                    _ngcontent-ng-c420556888=""
                    className="input-sub-headding"
                  >
                    My Casino Partnership
                  </h6>

                  {userType != "client" && (
                    <>
                      <div
                        _ngcontent-ng-c420556888=""
                        className="col-md-3 col-6"
                      >
                        <div
                          _ngcontent-ng-c420556888=""
                          className="clearfix_input"
                        >
                          <label _ngcontent-ng-c420556888="" htmlFor="">
                            Casino Partnership{" "}
                          </label>
                          <input
                            type="number"
                            max={
                              clientData?.partnershipAndCommission
                                ?.myCasinoPartnership
                            }
                            min={0.0}
                            defaultValue={0}
                            id="casino_commission"
                            required=""
                            value={payload?.myCasinoPartnership}
                            name="casinoCommission"
                            onChange={(e) =>
                              setPayload({
                                ...payload,
                                myCasinoPartnership: e.target.value,
                              })
                            }
                            className="form-control disabled ng-untouched ng-pristine ng-invalid"
                          />
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c420556888=""
                        className="col-md-3 col-6"
                      >
                        <div
                          _ngcontent-ng-c420556888=""
                          className="clearfix_input"
                        >
                          <label _ngcontent-ng-c420556888="" htmlFor="">
                            My Casino Share
                          </label>
                          <input
                            type="number"
                            value={
                              clientData?.partnershipAndCommission
                                ?.myCasinoPartnership
                            }
                            readOnly
                            className="form-control disabled ng-untouched ng-pristine ng-invalid"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              <div _ngcontent-ng-c420556888="" className="row">
                <h6
                  _ngcontent-ng-c420556888=""
                  className="input-sub-headding"
                >
                  My Casino Commission
                </h6>

                <div _ngcontent-ng-c420556888="" className="col-md-3">
                  <div _ngcontent-ng-c420556888="" className="clearfix_input">
                    <label _ngcontent-ng-c420556888="" htmlFor="">
                      My Casino Commission
                    </label>
                    <select
                      _ngcontent-ng-c420556888=""
                      aria-label="Default select example"
                      value={isCasinoCommission}
                      onChange={(e) => setIsCasinoCommission(e.target.value)}
                      formcontrolname="session_commission_type"
                      className="form-select form-control ng-untouched ng-pristine ng-invalid"
                    >
                      <option
                        _ngcontent-ng-c420556888=""
                        value="false"
                        selected=""
                      >
                        Commission Type
                      </option>
                      <option value="false" _ngcontent-ng-c420556888="">
                        No Comm
                      </option>
                      <option value="yes" _ngcontent-ng-c420556888="">
                        Bet By Bet
                      </option>
                    </select>
                  </div>
                </div>
                {isCasinoCommission == "yes" && (
                  <>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          Casino Commission{" "}
                        </label>
                        <input
                          type="number"
                          max={
                            clientData?.partnershipAndCommission
                              ?.casinoCommission
                          }
                          min={0.0}
                          defaultValue={0}
                          id="casino_commission"
                          required=""
                          value={payload?.casinoCommission}
                          name="casinoCommission"
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              casinoCommission: e.target.value,
                            })
                          }
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="col-md-3 col-6"
                    >
                      <div
                        _ngcontent-ng-c420556888=""
                        className="clearfix_input"
                      >
                        <label _ngcontent-ng-c420556888="" htmlFor="">
                          My Casino Commission
                        </label>
                        <input
                          type="number"
                          value={
                            clientData?.partnershipAndCommission
                              ?.casinoCommission
                          }
                          readOnly
                          className="form-control disabled ng-untouched ng-pristine ng-invalid"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div _ngcontent-ng-c420556888="" className="jmd_input_btn">
                <button
                  _ngcontent-ng-c420556888=""
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  _ngcontent-ng-c420556888=""
                  type="button"
                  onClick={() => {
                    window.location.href = `/${userType}`;
                  }}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          _ngcontent-ng-c420556888=""
          id="detailaModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          className="sitemoadal modal fade"
        >
          <div
            _ngcontent-ng-c420556888=""
            className="modal-dialog modal-dialog-centered"
          >
            <div _ngcontent-ng-c420556888="" className="modal-content">
              <div _ngcontent-ng-c420556888="" className="modal-header">
                <h5
                  _ngcontent-ng-c420556888=""
                  id="exampleModalLabel"
                  className="modal-title"
                >
                  User Deatils
                </h5>
                <button
                  _ngcontent-ng-c420556888=""
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close"
                />
              </div>
              <div _ngcontent-ng-c420556888="" className="modal-body">
                <div _ngcontent-ng-c420556888="" className="bet-slip-box">
                  <div _ngcontent-ng-c420556888="" className="bet-slip">
                    <div _ngcontent-ng-c420556888="" className="bet-nation">
                      <span _ngcontent-ng-c420556888="">UserName : </span>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="match-result"
                    />
                  </div>
                  <div _ngcontent-ng-c420556888="" className="bet-slip">
                    <div _ngcontent-ng-c420556888="" className="bet-nation">
                      <span _ngcontent-ng-c420556888="">password :</span>
                    </div>
                    <div
                      _ngcontent-ng-c420556888=""
                      className="match-result"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c420556888=""
                    className="place-bet-btn mt-4 text-align"
                  >
                    <button
                      _ngcontent-ng-c420556888=""
                      className="btn btn-primary btn-block"
                    >
                      <span _ngcontent-ng-c420556888="">
                        Share on WhatsApp
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </app-create-user>
      {/**/}
    </div>
  );
}
